<template>
  <div class="app-container">
<!--    <header>个人中心</header>-->
    <div class="content" v-loading='initLoading'>
      <el-tabs v-model="activeName" class="tabTitle">
        <el-tab-pane label="个人资料" name="first">
          <el-form :model="ruleForm" :rules="rule" ref="ruleForm" label-width="4rem" class="form1">
            <el-form-item label="账号" prop="phone">
              <el-input type="text" v-model="ruleForm.phone" disabled></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="name">
              <el-input type="text" v-model="ruleForm.name" maxlength="16"></el-input>
            </el-form-item>
            <!--<div class="promp">
              <i class="iconfont iconicon1-12"></i>
              <div>当您录凭证或者审核凭证时，此姓名即为您的制单人姓名、审核人姓名</div>
            </div>-->
          </el-form>
          <div class="button">
            <el-button type="primary" class="btn" @click="submitForm('ruleForm')">保存</el-button>
            <el-button class="btn" @click="back()" >取消</el-button>
          </div>
        </el-tab-pane>

        <el-tab-pane label="修改密码" name="second">
            <el-form ref="pwdForm" :rules="pwdRules" :model="pwdForm" class="form2" label-width="24%">
              <el-form-item label="账号" prop="phone">
                <el-col :span="24">
                  <el-input type="text"  v-model="pwdForm.phone" disabled maxlength="16" />
                </el-col>
              </el-form-item>
              <el-form-item label="原密码" prop="origPassword">
                <el-col :span="24">
                  <el-input placeholder="请输入原密码" v-model="pwdForm.origPassword" autocomplete="new-password" show-password maxlength="20" />
                </el-col>
              </el-form-item>
              <el-form-item label="新密码" prop="newPassword" style="margin-bottom: 0">
                <el-col :span="24">
                  <el-input placeholder="请输入新密码" v-model="pwdForm.newPassword" show-password maxlength="20"/>
                <!--  <span class="tip">8-20位,同时包含数字、大小写字母</span>-->
                </el-col>
              </el-form-item>
              <div :class="{tip_password:true,error:isError}" style="margin-left:24%;height: 1.55rem;">
                {{errorMsg}}
              </div>
              <el-form-item label="确认新密码" prop="confirmNewPassword">
                <el-col :span="24">
                  <el-input placeholder="请确认新密码" v-model="pwdForm.confirmNewPassword" show-password maxlength="20">
                  </el-input>
                </el-col>
              </el-form-item>
            </el-form>
            <div class="button">
              <el-button type="primary" class="btn" @click="changeEaycPwd" style="margin-left: 70px" :loading='isLoading'>确认修改</el-button>
              <el-button class="btn"  @click="back()">取消</el-button>
            </div>
        </el-tab-pane>

      </el-tabs>
    </div>
    <!--<info-dialog :config="config" :visible="dialogVisible" class="info_dialog" :cancelDialog="cancelDialog">
      <div class="question">您修改了姓名，请确认是从您的下一张凭证开始生效，还是您的全部凭证同步生效？</div>
      <div class="promp"><i class="iconfont iconicon1-12"></i>提示：您的制单人姓名、审核人姓名将同时生效。</div>
      <div class="dialog_btn">
        <el-button @click="nextVoucherEffect" type="primary" :loading='diaLoding'>下张凭证生效</el-button>
        <el-button @click="allVoucherEffect" type="primary" class="second" :loading='diaLoding'>全部凭证生效</el-button>
      </div>
    </info-dialog>-->

    <msg :icon="icon" :message="message" :color='color' :promptVisible="promptVisible" :title="title" :column="true" :row="false" top="22vh" class="employ_msg" :showClose="true" />

  </div>
</template>

<script>
  import md5 from 'js-md5'
  import encrypt from '@/common/jsencrypt'
  import infoDialog from '@/components/Dialog'
  import { getPersonInfo, editPersonInfo } from '@/api/user';
  import Cookies from "js-cookie";
  export default {
    name: "addCompany",
    components: {
      infoDialog
    },
    data() {
        let validatePwd = (rule, value, callback) => {
          var reg = /^(?![^a-zA-Z]+$)(?!\D+$)/
          if (value == '') {
            this.errorMsg = '请输入密码';
            this.isError = true;
          }else if (value.length < 8  || value.length > 16) {
            this.isError = true;
            this.errorMsg = '必须包含数字、字母，长度至少为8位且不超过16位';
          }else if(!reg.test(value)){
            this.errorMsg = '必须包含数字、字母，长度至少为8位且不超过16位';
            this.isError = true;
          }else if(this.pwdForm.newPassword === this.pwdForm.origPassword){
            this.errorMsg = '新密码与旧密码相同';
            this.isError = true;
          }else if(this.pwdForm.confirmNewPassword !== ''){
            this.$refs.pwdForm.validateField('confirmNewPassword')
            this.isError = false;
            callback();
          }else {
            this.isError = false;
            this.errorMsg = '';
            callback();
          }
      }
      let confirmNewPassword = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.pwdForm.newPassword) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        activeName: 'first',
        initLoading : false,
        isLoading : false,

        promptVisible : false,
        icon : 'iconfont iconicon1-17',
        color: '#35C4B1',
        message : '修改提示',
        title: '保存成功',
        ruleForm: {
          phone: '',
          name: ''
        },
        isError: false,
        errorMsg: '8-16位,同时包含数字和字母',
        rule: {
          name: [{ required: true, message: "请输入姓名" }, { min: 1, max: 16, message: "姓名长度在 1 到 16 个字符" }],
        },
        pwdForm:{
          phone:'',
          origPassword :'',
          newPassword :'',
          confirmNewPassword :'',
        },
        pwdRules: {
          origPassword: [{ required: true, message: "请输入原密码" }, { min: 8, max: 20, message: "原密码长度在 8 到 16 个字符" }],
          newPassword: [
              // { required: true, message: "请输入新密码" },
              // { min: 8, max: 20, message: "新密码长度在 8 到 20 个字符" },
              // { validator: validatePwd, trigger: 'blur' },
              { required: true, validator: validatePwd, trigger: 'blur' }
            ],
          confirmNewPassword: [
              // { required: true, message: "请确认新密码" },
              // { min: 8, max: 20, message: "新密码长度在 8 到 20 个字符" },
              // { validator: confirmNewPassword, trigger: 'blur' },
              { required: true, validator: confirmNewPassword, trigger: 'blur' }
            ],

        },

        dialogVisible: false,
        config: { top: '20vh', width: '420px', title: '提示', center: false, btnTxt: [] },
        scope: '',
        diaLoding : false,
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            /*this.dialogVisible = true*/
            this.editEaycInfo()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      cancelDialog() {
        // this.dialogVisible = false
      },
      back() {
        this.$router.go(-1)
      },
      getInfo() {
        this.initLoading = true
        getPersonInfo({
          userId: this.getToken('userId')
        }).then(res => {
          if (res.success) {
            this.initLoading = false;
            this.ruleForm = res.data;
            this.pwdForm.phone =res.data.phone
          } else {
            this.initLoading = false;
            einvAlert.error("提示",res.msg);
          }
        }).catch(err => {
         // einvAlert.error("提示",err)
        })
      },
      editInfo() {
        this.diaLoding = true;
        this.$store.dispatch('EditPersonInfo', {
          userId: this.getToken('userId'),
          name: this.ruleForm.name,
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            einvAlert.success('保存成功','已修改成功')
          } else {
            this.isLoading = false;
            einvAlert.error('保存失败',res.msg)
          }
        }).catch(err => {
          this.isLoading = false;
          einvAlert.error('保存失败',err)
        })
      },
      changePwd() {
        this.$refs.pwdForm.validate((valid) => {
          if (valid) {
            this.isLoading = true;
            this.$store.dispatch('ChangePwd', {...this.pwdForm,
                  oldPassword:md5(this.pwdForm.origPassword),
              newPassword:encrypt.encrypt(this.pwdForm.newPassword),
              confirmNewPassword:encrypt.encrypt(this.pwdForm.confirmNewPassword),
              userId:this.getToken('userId')}
              ).then((data) => {
              if (data.success) {
                this.isLoading = false;
                einvAlert.success('保存成功','已修改成功')
                // setTimeout(() => {
                //   Cookies.remove("Authorization");
                //   sessionStorage.clear();
                //   localStorage.clear();
                //   this.$router.replace('/login')
                // }, 2000)
              } else {
                this.isLoading = false;
                // einvAlert.error("提示",data.msg);
                einvAlert.error('保存失败',data.msg)
              }
            }).catch((error) => {
              console.log(error);
              this.promptVisible = false
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      editEaycInfo() {
        this.diaLoding = true;
        this.$store.dispatch('EditEaycPersonInfo', {
          userId: this.getCookies('eaycUserId'),
          name: this.ruleForm.name,
          callType:'1'
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            einvAlert.success('保存成功','已修改成功')
          } else {
            this.isLoading = false;
            einvAlert.error('保存失败',res.msg)
          }
        }).catch(err => {
          this.isLoading = false;
          einvAlert.error('保存失败',err)
        })
      },
      changeEaycPwd() {
        this.$refs.pwdForm.validate((valid) => {
          if (valid) {
            this.isLoading = true;
            if("einv"==process.env.VUE_APP_GROUP){
              this.$store.dispatch('ChangePwd', {...this.pwdForm,
                origPassword:md5(this.pwdForm.origPassword),
                newPassword:encrypt.encrypt(this.pwdForm.newPassword),
                confirmNewPassword:encrypt.encrypt(this.pwdForm.confirmNewPassword),
                userId:this.getToken('userId')}
              ).then((data) => {
                if (data.success) {
                  this.isLoading = false;
                  /*                this.message = "已修改成功"
                                  this.promptVisible = true
                                  setTimeout(() => {
                                    this.promptVisible = false
                                  }, 1000)*/
                  einvAlert.success('保存成功','已修改成功')
                  // setTimeout(() => {
                  //   Cookies.remove("Authorization");
                  //   sessionStorage.clear();
                  //   localStorage.clear();
                  //   this.$router.replace('/login')
                  // }, 2000)
                } else {
                  this.isLoading = false;
                  // einvAlert.error("提示",data.msg);
                  einvAlert.error('保存失败',data.msg)
                }
              }).catch((error) => {
                console.log(error);
                this.promptVisible = false
              })
            }else{
              this.$store.dispatch('ChangeEaycPwd', {
                    oldPassword:md5(this.pwdForm.origPassword),
                    newPassword:md5(this.pwdForm.newPassword),
                    userId:this.getCookies('eaycUserId')
                  }
              ).then((data) => {
                if (data.success) {
                  this.isLoading = false;
                  einvAlert.success('保存成功','已修改成功')
                  // setTimeout(() => {
                  //   sessionStorage.clear();
                  //   localStorage.clear();
                  //   this.$router.replace('/login')
                  // }, 2000)
                } else {
                  this.isLoading = false;
                  // einvAlert.error("提示",data.msg);
                  einvAlert.error('保存失败',data.msg)
                }
              }).catch((error) => {
                console.log(error);
                this.promptVisible = false
              })
            }






          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    mounted() {
      this.getInfo()
    },
  };
</script>

<style lang='less' scoped>
  .app-container {
    z-index: 140;
    position: absolute;
    height: 100% !important;
  }
  .content {
    padding: 0 20px;
    height: calc(100% - 2rem);
    margin: 0 auto;
    background: #fff;
  }
  .form1 {
    padding: 28px 28px 0 28px;
    width: 28.25rem;
    margin: 0 auto;
    .promp {
      margin-bottom: 32px;
      color: #ff9700;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding-left: 1.5rem;
    }
    .el-button {
      width: 150px;
      height: 40px;
    }
    .cancel_btn {
      margin-left: 16px;
      color: #4f71ff;
      border-color: #4f71ff;
    }
  }
  .form2 {
    padding: 28px 28px 0 28px;
    width: 540px;
    margin: 0 auto;
  }
  .line {
    text-align: center;
  }
  .button {
    margin: 40px auto 0;
    text-align: center;
    .btn {
      width: 150px;
      height: 40px;
      margin-right: 12px;
    }
  }
  .employ_msg {
    /deep/.el-dialog {
      // height: 160px;
      width: 280px !important;
      border-radius: 8px;
    }
  }
  /deep/ .el-input__inner{
    width: 360px;
    height: 48px;
  }
  .tip_password{
    color: #999999;
    font-size: 10px;
  }
  .error{
    color: #f56c6c;
  }
  header {
    background-color: #fff;
    padding-bottom: 15px;
    font-size: 16px;
  }
</style>
